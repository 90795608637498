import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"

const Curriculum = () => (
	<Layout>
		<SEO title="Curriculum" description="" />
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h5 class="font-weight-bold text-uppercase text-orange flair mb-0">
						TRAININGS
					</h5>
					<h1>Curriculum</h1>
				</div>
			</div>
		</div>
		<div class="page-content">
			
				               <style dangerouslySetInnerHTML={{__html: ".embed-container { position: relative; padding-bottom: 56.25%; height: 1400px; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }" }} /><div className="embed-container"><iframe src="https://narm.kartra.com/page_embed/Asv141" style={{border: 0}} /></div>
			
		</div>
	</Layout>
)

export default Curriculum
